export const Protocols = {
  UploadServiceAgreement: 0
}

const protocols = {
  [Protocols.UploadServiceAgreement]: {
    name: '《内容上传服务协议》',
    key: Protocols.UploadServiceAgreement,
    title: '机灵树智慧美育云平台内容上传服务协议',
    url:
      'https://public.jilingshu.com.cn/protocols-files/uploadServiceAgreement.protocol'
  }
}

export default protocols
