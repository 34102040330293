export default {
  mainApps: {
    shenyue: {
      themeColor: '#1890ff',
      baseURL: 'https://platform-api.jilingshu.com.cn',
      apiPrefix: '/backend/resource'
    },
    teacherPlatform: {
      themeColor: '#1ec38f',
      baseURL: 'https://user-api.jilingshu.com.cn',
      apiPrefix: '/teacher/resource'
    },
    schoolPlatform: {
      themeColor: '#1ec38f',
      baseURL: 'https://user-api.jilingshu.com.cn/',
      apiPrefix: '/teacher/resource'
    },
    institutionPlatform: {
      themeColor: '#1890ff',
      baseURL: 'https://resource-center.jilingshu.com.cn',
      apiPrefix: ''
    },
    // 通用服务接口调用相关配置
    commonService: {
      baseURL: 'https://gateway.jilingshu.com.cn',
      apiPrefix: '/api/common-service'
    },
    questionsBank: {
      baseURL: 'https://exam-center-service.jilingshu.com.cn',
      apiPrefix: ''
    }
  },
  serverURL: 'https://resource.jilingshu.com.cn',
  pptist: {
    name: 'pptist',
    entry: 'https://ppt.jilingshu.com.cn'
    // entry: 'http://localhost:8090'
  },
  knowledgePoint: {
    name: 'knowledgePoint',
    entry: 'https://ppt.jilingshu.com.cn'
  },
  musicScore: 'https://music-score.jilingshu.com.cn'
}
