<template>
  <div class="searchDiv">
    <search-bar v-model:value="formState.name" :on-search="getList" />
  </div>
  <div v-show="!pageData.firstLoading">
    <div class="formDiv">
      <a-form layout="inline">
        <a-row style="width: 100%" :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="歌曲识别码">
              <a-input v-model:value="formState.song_code" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="歌曲简拼">
              <a-input v-model:value="formState.py" />
            </a-form-item>
          </a-col>
          <template v-if="pageData.advanced">
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select
                  v-model:value="formState.status"
                  placeholder="请选择"
                  default-value="0"
                >
                  <a-select-option :value="0">全部</a-select-option>
                  <a-select-option :value="1">上架</a-select-option>
                  <a-select-option :value="2">下架</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="标签">
                <a-select
                  v-model:value="formState.tag"
                  mode="multiple"
                  placeholder="可选择标签"
                  style="width: 100%"
                  :options="formOpts.tags"
                  @search="handleTagSearch"
                >
                  <template #suffixIcon><search-outlined /></template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="所属教材章节">
                <a href="#" @click="() => pageData.modalVisible = true">{{formOpts.bookOpts.showName}}</a>
              </a-form-item>
            </a-col>
          </template>
          <a-col :md="(!pageData.advanced && 8) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="
                (pageData.advanced && { float: 'right', overflow: 'hidden' }) ||
                {}
              "
            >
              <a-button type="primary" @click="getList">查询</a-button>
              <a-button style="margin-left: 8px" @click="handleReset"
                >重置</a-button
              >
              <a
                @click="
                  () => {
                    pageData.advanced = !pageData.advanced;
                  }
                "
                style="margin-left: 8px"
              >
                {{ pageData.advanced ? "收起" : "展开" }}
                <up-outlined v-show="pageData.advanced" />
                <down-outlined v-show="!pageData.advanced" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <div class="tableOperator">
        <a-button type="primary" @click="handleCreate" v-permission="PermissionMap.SHEET_SONGS_CREATE" v-show="pageData.type === 1">
          新建
        </a-button>
        <a-button type="primary" @click="handleCreate" v-permission="PermissionMap.SHEET_SONGS_PERSONAL_CREATE" v-show="pageData.type === 2">
          新建
        </a-button>
      </div>
    </div>
    <div class="tableDiv">
      <a-tabs v-model:activeKey="pageData.type" @change="handleTypeChange">
        <a-tab-pane v-for="tabItem in tabList" :key="tabItem.key" :tab="tabItem.title" />
      </a-tabs>
      <a-table
        :dataSource="tableState.data"
        :columns="tableState.columns"
        rowKey="id"
        :pagination="tableState.pagination"
        :loading="tableState.loading"
        @change="handleTableChange"
        :scroll="{ x: 1300 }"
      >
        <template #action="{ record }">
          <div v-if="pageData.type === 1">
            <router-link
              v-permission="PermissionMap.SHEET_SONGS_DETAIL"
              :to="`/sheet-songs/${record.id}/detail/1`"
            >
              详情
            </router-link>
            <!--          <a-divider-->
            <!--            v-permission="PermissionMap.SHEET_SONGS_VERSION"-->
            <!--            type="vertical"-->
            <!--          />-->
            <!--          <router-link-->
            <!--            v-permission="PermissionMap.SHEET_SONGS_VERSION"-->
            <!--            :to="`/sheet-songs/${record.id}/version`"-->
            <!--          >-->
            <!--            版本管理-->
            <!--          </router-link>-->
            <a-divider
              v-permission="PermissionMap.SHEET_SONGS_DETAIL"
              type="vertical"
            />
            <a
              v-permission="PermissionMap.SHEET_SONGS_COPY"
              href="#"
              @click="handleCopy(record)"
            >
              复制到我的歌曲
            </a>
            <a-divider
              v-permission="PermissionMap.SHEET_SONGS_COPY"
              type="vertical"
            />
            <router-link
              v-permission="PermissionMap.SHEET_SONGS_EDIT"
              :to="`/sheet-songs/${record.id}/edit/${pageData.type}`"
            >
              编辑
            </router-link>
            <a-divider
              v-permission="PermissionMap.SHEET_SONGS_DELETE"
              type="vertical"
            />
            <a-popconfirm
              title="确定删除这首歌曲吗?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a href="#" v-permission="PermissionMap.SHEET_SONGS_DELETE">删除</a>
            </a-popconfirm>
          </div>

          <div v-if="pageData.type === 2">
            <router-link
              v-permission="PermissionMap.SHEET_SONGS_PERSONAL_DETAIL"
              :to="`/sheet-songs/${record.id}/detail/2`"
            >
              详情
            </router-link>
            <a-divider
              v-permission="PermissionMap.SHEET_SONGS_PERSONAL_DETAIL"
              type="vertical"
            />
            <router-link
              v-permission="PermissionMap.SHEET_SONGS_PERSONAL_EDIT"
              :to="`/sheet-songs/${record.id}/edit/2`"
            >
              编辑
            </router-link>
            <a-divider
              v-permission="PermissionMap.SHEET_SONGS_PERSONAL_EDIT"
              type="vertical"
            />
            <a-popconfirm
              title="确定删除这首歌曲吗?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a href="#" v-permission="PermissionMap.SHEET_SONGS_PERSONAL_DELETE">删除</a>
            </a-popconfirm>
          </div>
        </template>
        <template #songType="{ text }">
          <a-tag :color="songType.get(text).presetColor">{{
            songType.get(text).name
          }}</a-tag>
        </template>
        <template #tags="{ text }">
          <span v-if="text && !text.length">--</span>
          <span v-else>
            <a-tooltip :title="text.map((tItem) => tItem.tag).toString()" placement="topLeft">
              <span :key="item.tag" v-for="item in text">
                <a-tag class="tag_item_label">{{ item.tag }}</a-tag>
              </span>
            </a-tooltip>
          </span>
        </template>
      </a-table>
    </div>
  </div>
  <a-skeleton v-if="pageData.firstLoading" />
  <select-book-modal
    ref="modalRef"
    :type="pageData.type"
    v-model:visible="pageData.modalVisible"
    @ok="handleSelectBookSection"
  />
</template>

<script>
import SearchBar from '@/components/common/SearchBar'
import { DownOutlined, UpOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { computed, onBeforeMount, reactive, ref } from 'vue'

import { getTags, getTeacherTags } from '@/services/common'
import {
  getOfficialSheetSongs,
  deleteOfficialSheetSong,
  getPersonalSheetSongs,
  deletePersonalSheetSong,
  copySheetSong
} from '@/services/songs'
import { debounce } from '@/utils/common'
import { songType } from '@/utils/dataMap/song'
import { useRouter } from 'vue-router'
import { filterPermiison, PermissionMap } from '@/utils/auth'
import SelectBookModal from '../components/SelectBookModal'

export default {
  name: 'List',
  components: {
    SearchBar,
    DownOutlined,
    UpOutlined,
    SearchOutlined,
    SelectBookModal
  },
  setup () {
    const tabList = filterPermiison([
      {
        key: 1,
        title: '官方歌曲'
      },
      {
        key: 2,
        title: '我的歌曲',
        permission: PermissionMap.SHEET_SONGS_PERSONAL_LIST
      }
    ])
    const modalRef = ref()
    const pageData = reactive({
      firstLoading: true,
      advanced: false,
      type: 1,
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      },
      modalVisible: false
    })
    const formState = reactive({
      name: '',
      song_type: -1,
      book_section_id: [],
      song_code: '',
      py: '',
      status: 0
    })
    const formOpts = reactive({
      songType: [],
      bookOptions: [],
      bookOpts: {
        showName: '教材名称-章节目录'
      },
      defaultBookOpts: {
        showName: '教材名称-章节目录'
      }
    })

    for (const [k, v] of songType) {
      formOpts.songType.push({ value: k, label: v.name })
    }

    const tableState = reactive({
      data: [],
      columns: [],
      loading: true,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showTotal: total => `共${total}条`,
        showQuickJumper: true
      }
    })

    tableState.columns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        ellipsis: true,
        width: 200
      },
      {
        title: '歌曲识别码',
        dataIndex: 'song_code',
        width: 140,
        ellipsis: true
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        width: 100,
        ellipsis: true
      },
      {
        title: '歌曲类型',
        dataIndex: 'song_type',
        width: 100,
        slots: { customRender: 'songType' }
      },
      {
        title: '歌曲速度',
        dataIndex: 'speed',
        width: 90
      },
      // {
      //   title: '状态',
      //   dataIndex: 'status',
      //   width: 100
      // },
      {
        title: '标签',
        dataIndex: 'tags',
        width: 200,
        ellipsis: true,
        slots: { customRender: 'tags' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at',
        width: 180,
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 230,
        slots: { customRender: 'action' }
      }
    ]

    const router = useRouter()

    const getList = debounce(async (params) => {
      const getSheetSong = pageData.type === 1 ? getOfficialSheetSongs : getPersonalSheetSongs
      tableState.loading = true
      params = { ...queryParams.value, page: 1, ...params }
      const { items, pagination } = await getSheetSong(params)
      tableState.data = items
      tableState.loading = false
      tableState.pagination = { ...tableState.pagination, ...pagination }
    })

    const handleTableChange = (pag, filters, sorter) => {
      tableState.pagination = pag
      getList({ page: pag.current })
    }

    const handleReset = () => {
      modalRef.value.handleReset()
      formState.song_type = -1
      formState.song_code = ''
      formState.py = ''
      formState.name = ''
      formState.book_section_id = []
      formState.tag = []
      formState.status = 0
      formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      getTagsOptions()
      getList()
    }

    const queryParams = computed(() => {
      const params = { ...formState }
      if (params.song_type === -1) {
        delete params.song_type
      }
      if (params.song_code === '') {
        delete params.song_code
      }
      if (params.py === '') {
        delete params.py
      }
      if (params.book_section_id.length === 0) {
        delete params.book_section_id
      } else if (params.book_section_id.length === 1) {
        params.book_id = params.book_section_id[0]
        delete params.book_section_id
      } else {
        params.book_id = params.book_section_id[0]
        params.book_section_id = params.book_section_id[params.book_section_id.length - 1]
      }
      for (const key in params) {
        if (params[key] === '') {
          delete params[key]
        }
      }
      params.per_page = tableState.pagination.pageSize
      params.page = tableState.pagination.current
      return params
    })

    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: pageData.tagParams.pageSize,
        current: pageData.tagParams.page,
        keyword: pageData.tagParams.keyword
      }
      const getTagsService = pageData.type === 1 ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const handleDelete = async record => {
      const deleteService = pageData.type === 1 ? deleteOfficialSheetSong : deletePersonalSheetSong
      await deleteService(record.id)
      message.success('删除成功')
      getList({ page: tableState.pagination.current })
    }

    const formatCascaderChildrenOpts = (children) => {
      return children.map(i => {
        const item = {
          label: i.name,
          value: i.id
        }
        if (i.children) {
          item.children = formatCascaderChildrenOpts(i.children)
        }
        return item
      })
    }

    const handleCreate = async () => {
      router.push(`/resource/sheet-songs/create/${pageData.type}`)
    }

    const handleTagSearch = v => {
      pageData.tagParams.page = 1
      pageData.tagParams.keyword = v
      getTagsOptions()
    }

    const handleSelectBookSection = (value) => {
      pageData.modalVisible = false
      if (value.values.length > 0) {
        formOpts.bookOpts.showName = value.labels.join('-')
        formState.book_section_id = value.values
      } else {
        formOpts.bookOpts.showName = formOpts.defaultBookOpts.showName
      }
      getList()
    }

    const handleTypeChange = () => {
      getList()
      getTagsOptions()
    }

    const handleCopy = async (record) => {
      const key = 'copy'
      try {
        await copySheetSong(record.id)
        message.loading({
          key,
          content: '正在处理'
        })
        message.success({
          key,
          content: '复制成功'
        })
      } catch (e) {
        message.error({
          key,
          content: '操作失败'
        })
      }
    }

    onBeforeMount(async () => {
      await Promise.all([
        getList(),
        getTagsOptions()
      ])
      pageData.firstLoading = false
    })

    return {
      tabList,
      modalRef,
      songType,
      pageData,
      formState,
      formOpts,
      tableState,
      getList,
      PermissionMap,
      handleTableChange,
      handleDelete,
      handleCreate,
      handleReset,
      handleTagSearch,
      handleSelectBookSection,
      handleTypeChange,
      handleCopy
    }
  }
}
</script>

<style lang="less" scoped>
.searchDiv {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 22px;
  background-color: #ffffff;
}
.formDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
}
.tableDiv {
  background-color: white;
  margin: 30px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    display: inline-block;
    font-size: 24px;
    margin: 2px 6px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.ant-col {
  margin-top: 16px;
}
.tableOperator {
  margin-top: 16px;
}

.tag_item_label {
  max-width: 100px;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  cursor: default;
}
</style>
